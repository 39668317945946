.App {
  text-align: left;
}

.accountIcon {
  margin-right: 10px;
}

a {
  color: #FFF;
  text-decoration: none;
}

a:visited {
  color: #FFF;
}

.App-body {
  padding: 25px 25px 25px;
}

.form-row {
  padding-top: 15px;
}

.dropdown-panel {
  border-bottom: #CCC 1px solid;
  padding-bottom: 12px;

}

.dropdown-title {
  padding-top: 7px;
}

.bottomRowSpacing {
  padding-bottom: 15px;
}

.method {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 16px;
  text-align: left;
  color: #666;
  margin: 0;
  padding-bottom: 0;
}

.serving {
  font-size: 12px;
  text-align: left;
  color: #666;
  margin: 0;
  padding-bottom: 0;
}

.ingredient-header {
  font-size: 15px;
  text-align: left;
  color: #444;
  margin: 0;
  padding-bottom: 0;
}

.dish-title {
  text-align: left;
  margin-bottom: 5px;
}

.dish-list {
  font-size: 15px;
  color: #666;
  padding-left: 12px;
  padding-bottom: 5px;
}

.shopping-list {
  font-size: 15px;
  color: #666;
  padding-left: 12px;
  padding-bottom: 5px;
  border-bottom: #EEE 1px solid;
}